@font-face {
    font-family: "Satoshi";
    font-display: block;
    src: local('Satoshi'), url('assets/fonts/Satoshi-Variable.woff2') format('woff2'),
        /* will be preloaded */
        url('assets/fonts/Satoshi-Variable.woff') format('woff'), url('assets/fonts/Satoshi-Variable.ttf') format('truetype'), url('assets/fonts/Satoshi-Variable.eot') format('embedded-opentype');
    /* src: url("assets/fonts/Satoshi-Variable.ttf") format("truetype"); */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* These styles will be purged */

@layer base {
    :root {
        --color-cyan-500: theme('colors.cyan.500');
        --color-purple-500: theme('colors.purple.500');
    }
}

@layer components {}

@layer utilities {}

* {
    font-family: 'Satoshi';
}

.card-shadow-cs {
    box-shadow: 0 10px 44px hsl(0deg 0% 0% / 5%), 0 20px 48px hsl(0deg 0% 0% / 5%), 0 1px 24px hsl(0deg 0% 0% / 5%);
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cub-1 {
    width: 20vh;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: scale(1, -1) translate(50%, 0%);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1) translate(50%, 0%);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
}

.cub-2 {
    width: 15vh;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(-5%, -20%);
}

.cub-3 {
    width: 5vh;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-300%, 300%);
}

.wrapper-screen {
    height: 100vh;
}

@media only screen and (max-width: 767.5px) {
    .cub-1 {
        width: 10vh;
    }

    .cub-2 {
        width: 7vh;
        transform: translate(-5%, -55%);
    }

    .cub-3 {
        transform: translate(-100%, 150%);
    }

    .flex-center {
        /* display: block !important;
        align-items: unset !important;
        justify-content: unset !important; */
        display: block !important;
        align-items: unset !important;
        justify-content: unset !important;
    }

    .wrapper-screen{
        height: 90vh;
    }
}

@media only screen and (max-width: 991.5px) {
    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}